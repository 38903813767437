import { List, ListItem, ListItemText, Typography } from "@mui/material";
import { CardContainer } from "../../Common/UI/components/CardContainer";

export default function DashboardHomeHelp() {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Welcome to our <a href="/">Tech Dashboard</a>, our approach to proactive
        monitoring and troubleshooting.
      </Typography>
      <Typography variant="body1" paragraph>
        The Dashboard goals are to <strong>Alert</strong>; display key
        information statuses & metrics consolidated from different systems,{" "}
        <strong>Publish</strong> key information to be available to our tech
        team members to better support our stores, <strong>Action</strong> when
        applicable, provide our Tech team members with ability to action on an
        issue.
      </Typography>
      <Typography variant="body1" paragraph>
        The HOME page is the landing page for our dashboard and should provide
        high level health for key systems & integrations.
      </Typography>
      <CardContainer>
        <ul>
          <li>
            <Typography variant="h6">NETWORK OFFLINE Widget</Typography>
            <ul>
              <li className="circle">
                <Typography variant="body1" component="span">
                  This Widget will display total numbers of stores where network
                  is deemed DOWN. The Dashboard will telnet into each stores'
                  WAN 1 & WAN 2 IPs (see Store 411 Network tab) and provide
                  telnet status. A store is considered down{" "}
                  <strong>if both</strong> WAN 1 & WAN 2 are offline.
                </Typography>
              </li>
            </ul>
          </li>

          <li>
            <Typography variant="h6">NETWORK STORE STATUS Widget</Typography>
            <ul>
              <li className="circle">
                <Typography variant="body1" component="span">
                  A list of all stores & their telnet status for both WAN1 & WAN
                  2 IPs (see Store 411 Network tab). Green checkmark indicates
                  network up, Red circle with X indicates network down. List of
                  stores that are offline will be displayed at the top of the
                  list.
                </Typography>
              </li>
            </ul>
          </li>

          <li>
            <Typography variant="h6">CARDFREE STORES OFFLINE Widget</Typography>
            <ul>
              <li className="circle">
                <Typography variant="body1" component="span">
                  This Widget will display total numbers of stores where
                  CardFree online status is set to DISABLED or hours of
                  operations is setup with same start & end hours.
                </Typography>
              </li>
            </ul>
          </li>

          <li>
            <Typography variant="h6">CARDFREE STORE STATUS Widget</Typography>
            <ul>
              <li className="circle">
                <Typography variant="body1" component="span">
                  A list of all stores and their status on CardFree (Green =
                  Online, Red X = Offline). Offline status on CardFree is
                  determined by either status is setup to DISABLED, or start &
                  end Hours of Operations are set to same hour. List of stores
                  that are offline will be displayed at the top of the list.{" "}
                </Typography>
              </li>
            </ul>
          </li>

          <li>
            <Typography variant="h6">UBER EATS STORES OFFLINE Widget</Typography>
            <ul>
              <li className="circle">
                <Typography variant="body1" component="span">
                This Widget will display total numbers of stores where Uber Eats online status is set to DISABLED or based on Hours of Operations 
                </Typography>
              </li>
            </ul>
          </li>

          <li>
            <Typography variant="h6">UBER EATS STORE STATUS Widget</Typography>
            <ul>
              <li className="circle">
                <Typography variant="body1" component="span">
                A list of all stores and their status on Uber Eats (Green = Online, Red X = Offline). Offline status on Uber Eats is determined by either status is setup to DISABLED, or based on Hours of Operations. List of stores that are offline will be displayed at the top of the list. 
                </Typography>
              </li>
            </ul>
          </li>

          <li>
            <Typography variant="h6">
              LATEST RUN INTEGRATION STATUS Detail
            </Typography>
            <ul>
              <li className="circle">
                <Typography variant="body1" component="span">
                  This lists key custom integrations and their status:
                </Typography>
                <ul>
                  <li>
                    Employee Punches Export from POS to UKG: This indicates if
                    the Punches from POS have been successfully sent to UKG.
                    This is a critical process and should not be red. If status
                    is Red, please create a ticket using + Ticket button.{" "}
                  </li>
                  <li>
                    Employee Punch Adjustments Export from POS to UKG: This
                    indicates if the Punches Adjustments files have been
                    successfully sent to UKG. It is normal if certain days,
                    usually one day at time, has no adjustments.{" "}
                  </li>
                  <li>
                    Torch Heroes - Export File to Rewards Gateway: This
                    indicates if the file from UKG via Datalake is sent to
                    Rewards Gateway to support our Torch Heros program.{" "}
                  </li>
                  <li>
                    Bartender Tips Export from UKG to DailyPay: This indicates
                    if the file from DOMO via Datalake has been sent to DailyPay
                    to facilitate Bartender Tips. This process runs at 3 PM and
                    is critical for TM. If it still shows Red after 3 PM, please
                    open a ticket.{" "}
                  </li>
                  <li>
                    CrunchTime - Import data into Datalake: This indicates if
                    data from CrunchTime has been successfully sent to Datalake.
                    Red here can impact Daily Sales Diff report used for
                    validation. Please open a ticket.{" "}
                  </li>
                  <li>
                    DOMO - Import data into Datalake, DOMO - Filtered data into
                    curated database: Both these processes indicate that DOMO
                    data has been sent to Datalake.{" "}
                  </li>
                  <li>
                    Bite - Import data into Datalake: This indicates that Bite
                    data has been sent to Datalake.{" "}
                  </li>
                  <li>
                    Employee Card: This indicates that Employee Card processing has completed successfully.{" "}
                  </li>
                </ul>
              </li>
              <li className="circle">
                <Typography variant="body1" component="span">
                  + Ticket button will automatically create a Fresh Service
                  ticket that is assigned to the ApplicationDev team where
                  Subject is pre-populated based on integration name.
                </Typography>
              </li>
            </ul>
          </li>
          <li>
            <Typography variant="h6">+ CREATE A TICKET button </Typography>

            <ul>
              <li className="circle">
                <Typography variant="body1" component="span">
                  Create a Fresh Service ticket, assigned to Application Dev
                  Team. Populate Subject, Priority & Description as needed.
                </Typography>
              </li>
            </ul>
          </li>
        </ul>
      </CardContainer>
    </>
  );
}
